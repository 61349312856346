import restaurant from "./images/23.png";
import biserica from "./images/catedrala.jpeg";
import img_card from "./images/alexandra.jpg";
import imgOmSuflet from "./images/ffru.png";
import imgOmSufletmb from "./images/ffmru.png";
import imgheader from "./images/f1ru.png";
import imgheadermb from "./images/f2mru.png";
import img2 from "./images/sa.jpg";
import imgheadermiini from "./images/fo.png";
import imgheadermiinimb from "./images/fo.png";
import imgconfirmare from "./images/fd.png";
import logo from "./images/logo.png";


const data = {
    introData: [{
        familia: "S & A",
        logo: logo,
        mire: "",
        mireasa: "",
        data: "",
        data_confirmare: "15 septembrie 2024",
        savedata: "",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "sashashv@gmail.com", 
       tel: "+12673341900",
       phone: "tel:+12673341900",
       viber: "viber://chat?number=%2B12673341900",
       whatsapp: "https://wa.me/+12673341900",
       messenger: "https://www.messenger.com/t/xxx",
       tel1: "+12153996011",
       phone1: "tel:+12153996011",
       viber1: "viber://chat?number=%2B12153996011",
       whatsapp1: "https://wa.me/+12153996011",
       messenger1: "https://www.messenger.com/t/xxx",
    }],
    cardData: [
        {
            id: 1,
            img: biserica,
            title: "Крещения Александры",
            localul: "Собор",
            name: "Рождества Христова",
            data: "5 октября 2024, суббота, 14:00",
            adress: "бульвар Штефан чел Маре ши Сфынт, 138",
            harta: "https://maps.app.goo.gl/tboFu7zTpWU4x5FT8",
            iframe: ""
        },
        {
            id: 2,
            img: restaurant,
            title: "Кумэтрия Себастьяна и Александры",
            localul: "Ресторан",
            name: "Villa Garden",
            data: "5 октября 2024, суббота, 16:00",
            adress: "бульвар Дечебал 16, Кишинёв",
            harta: "https://maps.app.goo.gl/pwqyQnndwKAeacit7",
            iframe: "" 
        },
       

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "Таинство Крещения",
            title3: "АЛЕКСАНДРЫ",
            mami: "Cristina",
            tati: "",
            message: "Приглашаем Вас разделить с нами особо радостный день - крещение нашей любимой доченьки! ",
            message1: "Обряд Крещения состоится в Кишинёвском Кафедральном Соборе «Рождества Христова»,",
        message2:" 5 октября 2024, в 14:00."
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img2,
            title1: "",
            title2: "Кумэтрия",
            title3: "СЕБАСТЬЯНА И АЛЕКСАНДРЫ",
            message: 'Приглашаем Вас на кумэтрию наших детей в кругу близких и лучших друзей!',
           message1: 'Празднование состоится в ресторане «Villa Garden», ',
        message2:" 5 октября 2024, в 16:00."
        } 
    ],
    blogDataOmSuflet: [
        {

            imgdesktop: imgOmSuflet,
            imgmobil: imgOmSufletmb,
            title: "",
            message: "",
            message1: "",
            parintii_nume1: "",
            parintii_nume2: "",
            parintii_nume3: "",
            parintii_nume4: "",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Если вы читаете это сообщение, значит мы ценим вас и будем очень рады если вы присоединитесь к нам в такой особенный день для нас!",
            message: "",
        }
    ],

    blogDataConfirmare: [
        {
            imgdesktop: imgconfirmare,
            imgmobil: imgconfirmare,
            title: "Нам будет приятно если возьмете с собой хорошее настроение и парочку тостов в нашу честь.",
            message: "P.S: Будем признательны, если вы подтвердите свое присутствие!",
        }
    ],

}

export default data;