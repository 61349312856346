import React from "react";
import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";
import FavoriteIcon from '@mui/icons-material/Favorite';
import { red } from "@mui/material/colors";
import CardLocal from "../cardLocal";
import FormSection from "../FormSection";
import BlogSectionNoi from "../blogSectionNoi";
import BlogSectionNasii from "../blogSectionNasii";
import data from "../Data";
import Slide  from "./Slide";



const Home = (props) => {


    return (



        <ParallaxProvider>

            {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height={window.innerWidth >= 850 ? '850px' : '550px'} opacity=".5" >
                    
                        <div className="row text-center align-items-center" style={{ position: 'absolute', }}>
                            <div style={{ maxWidth: '600px', padding: '10px', }}className="col-12  ">
                                <h1 style={{ fontSize: '60px' }}>{item.mire}  {item.mireasa} </h1>

                                <h4 style={{ fontSize: '36px' }}>{item.savedata}<br />{item.data}</h4>
                                
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}
<div className="container text-center " style={{maxWidth: '600px', padding: '25px' }}>
  <Timer />  
</div>


            

        {data.blogDataDrum.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="450px" opacity=".5">
                        <div className="mask" > </div> 
                        <div className="row text-center align-items-center" style={{ position: 'relative', }}>
                            <div className="col-12 " style={{ maxWidth: '600px', padding: '30px', zIndex: '3'  }}>
                                <h1 style={{ fontSize: '25px',  }}><b>{item.title}</b></h1>
                                <h4 >{item.message}</h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })} 

<BlogSectionNoi />
<BlogSectionNasii />
            
<Slide/>
     

{data.blogDataOmSuflet.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height={window.innerWidth >= 850 ? '650px' : '300px'} opacity=".2">
                        <div className="text-center align-items-center" style={{ position: 'absolute'}}>
                           
                                <h1 style={{ fontSize: '70px' }}>{item.title}</h1>
                                <h4 className="mb-4">{item.message}</h4>
                                <h5 className="mb-4">{item.message1}</h5>
                                {/*<div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12" >
                                    <h1 className="pb-3" style={{ fontSize: '30px'}}>{item.parintii_nume1}<br/>{item.parintii_nume2}</h1>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                    <h1 style={{ fontSize: '30px'}}>{item.parintii_nume3}<br/>{item.parintii_nume4}</h1>
                                    </div>
                </div>
                                <FavoriteIcon sx={{ color: red[500], fontSize: 40 }} />*/}
                                <h4 className="mb-4 mt-3" style={{ fontSize: '20px' }} >{item.nasii}</h4>
                                <h1 style={{ fontSize: '30px' }}>{item.nasii_nume}</h1>
                            
                        </div>
                    </Parallaximg>
                )
            })}



         <CardLocal />

         {data.blogDataConfirmare.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="400px" opacity=".5">
                       <div className="mask"></div>
                        <div className="row text-center align-items-center" style={{ position: 'relative' }}>
                            <div className="col-12 " style={{ maxWidth: '600px', padding: '20px', zIndex: '3'    }}>
                                <h1 style={{ fontSize: '25px',  }}><b>{item.title}</b></h1>
                                <h4 >{item.message}</h4>
                            </div>
                        </div>
                    </Parallaximg>
                )
            })} 
            

            <FormSection />

            {data.introData.map((item) => {
                return (
                    <div>
                        <h4 className="text-center mt-5" >
                        С радостью и открытым сердцем ждём вас!
                        </h4>

                        <h3 className="text-center mb-5" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                             {item.mireasa}  {item.mire}
                        </h3>
                    </div>

                )
            })}





        </ParallaxProvider>



    )
}

export default Home;